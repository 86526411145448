export default {
  "Accept-Language": "JP", //请求头
  ajaxKey: "expand2",
  // 路由
  router: {
    index: "トップページです",
    introduction: {
      index: "観光スポットの紹介",
      list: "名所の紹介",
      detail: "名所の紹介",
    },
    reserve: {
      index: "オンライン予約です",
      list: "オンライン予約です",
    },
    information: {
      index: "観光地情報です",
      list: "観光地情報です",
      detail: "情報詳細です",
    },
    activity: {
      index: "イベント予告です",
      list: "イベント予告です",
      detail: "イベントの詳細です",
    },
    strategy: {
      index: "遊びの攻略法です",
      trafficList: "観光地交通です",
      accommodationList: "宿泊グルメ",
      detail: "詳細です",
    },
    consult: {
      index: "オンライン相談です",
      form: "オンライン相談です",
      detail: "詳細です",
    },
  },
  // indexPage: "トップページです",
  // 顶部导航栏
  nav: {
    indexPage: "トップページです",
    externalLink: "江山旅遊サイトです",
    //   index: "トップページです",
    //   introduction: {
    //     index: "景勝地の案内です",
    //     scenicSpot: "スポット紹介です",
    //     virtual: "バーチャルツアーです",
    //     voice: "音声による解説です",
    //     scenery: "景勝地です",
    //     guide: "観光地案内です",
    //   },
    //   reserve: "オンライン予約",
    //   information: "観光地情報です",
    //   activity: "イベント予告です",
    //   strategy: {
    //     index: "観光ガイド",
    //     traffic: "観光地交通です",
    //     accommodation: "宿泊グルメ",
    //   },
  },
  // 左侧菜单
  rightNav: {
    ticket: "チケット予約です",
    consult: "オンライン相談です",
    visitor: "観光地の人の流れです。",
    tikTok: "メディアの注目です",
    top: "てっぺん",

    medium: {
      tikTokScan: "ドローンコード",
      tikTokAttention: "ティックトックに注目です",

      wechatScan: "微信コードスキャン",
      wechatAttention: "公式アカウントをフォローします",
      wechatAttentionTravel: "旅行番号に注目します",
    },
  },
  // 景区流量
  scenery: {
    // Jianglang: "江郎山景区",
    // Nianbadu: "廿八都古镇",
    // Qingyang: "清漾景区",

    load: "景観区の積載量です",
    instantaneousLoad: "瞬時に積載できる量です",
    people: "リアルタイムの観光客数です",
  },

  // 底部信息
  footer: {
    link: "友情のリンクです",
    linkList: [
      {
        label: "江山旅発ホームページ",
        value: "http://www.zjjslf.com/",
      },
      // {
      //   label: "江山情報網です",
      //   value: "http://www.21js.com/",
      // },
      {
        label: "江山政府網です",
        value: "http://www.jiangshan.gov.cn/",
      },
      {
        label: "江山新聞網",
        value: "http://www.js-news.cn/",
      },
      {
        label: "江山旅遊サイトです",
        value: "http://ct.zj.gov.cn/",
      },
    ],
    copyright:
      "主催:江山市観光発展有限会社 電話:0570-4015911 住所:江山市景星西路万商城10号 技術支援:衢報智慧科技有限会社",
    recordFiling: {
      mps: "浙江公網安備33088102002129号",
      icp: "浙江ICP備16031112号-1です",
    },
  },
  // 分页
  page: {
    prev: "前のページです",
    next: "次ページです",

    pagePrefix: "",
    pageSuffix: "ページ目です",

    goToPrefix: "",
    goToSuffix: "ページまでです",

    confirm: "確定です",

    totalPrefix: "全",
    totalSuffix: "ページです",
  },
  all: "全部です",

  breadcrumb: "現在の位置です",
  vr: "バーチャルツアーです",
  voice: "音声による解説です",
  traffic: "観光地交通です",
  food: "フードエンターテインメントです",
  accommodation: "宿泊のお勧めです",
  consult: "オンライン相談です",
  reserve: "オンライン予約です",
  buyTicket: "チケットを購入オンライン",

  introduction: "景勝地の案内です",
  booking: "チケットの予約をします",
  detail: "詳細です",
  more: "もっとです",

  startingPrice: "スタート価格",

  // 首页
  index: {
    firstTitle: "江郎山・二十八都旅区です",
    firstTips:
      "江郎山・廿八都旅区は江郎山、廿八都古鎮、清漾の3つの主要な部分からなり、丹霞地形、古建民居、毛氏文化などの観光資源が豊富で、世界自然遺産、国家級風景名勝区、国家AAAAA級観光地となっています。",
    information: "観光地情報です",
    informationTips: "様々な観光スポットのニュースやメディア情報です",
    activity: "イベント予告です",
    activityTips: "各景勝地で行われているイベントの予告やニュース記事です",
    activityAll: "全ての情報です",

    food: "宿泊、グルメ、エンターテイメントです",
    foodDetail: "詳細です",
  },
  // 景区介绍
  introductionPage: {
    scenicSpot: "名所",
    introduce: "紹介です",
    tips: "江郎山・廿八都観光区—優越な地理位置と天然の美しい環境資源を有して、旅行者の休憩、健康、運働、レジャー娯楽などの需要を満足します",

    scenicArea: "景勝地の",
    scenery: "風景です",
  },
  // 在线预订
  reservePage: {
    ticket: "チケットです",
    reserve: "予定です",
    tips: "オンラインで各大景勝地のチケットを予約して、優待の価格ですばやく予約して安全にチケットを出します",

    monthlySales: "月別販売量",
    comments: "レビューです",
    time: "オープン時間です",
    address: "アドレスです",
  },
  // 景区资讯
  information: {
    information: "観光地情報です",
    ticket: "チケット予約です",
    reserve: "予定です",
  },
  // 活动预告
  activity: {
    activity: "イベント",
    notice: "予告です",
    tips: "各景勝地のイベント予告やイベント関連のニュースなどが掲載されています。",
  },

  // 游玩攻略
  strategy: {
    // 景区交通
    traffic: {
      scenicArea: "景勝地です",
      traffic: "交通です",
      tips: "各景勝地の交通情報,大交通及び景勝地内のルート情報です",
    },
    // 住宿美食
    food: {
      food: "宿泊、グルメ、エンターテイメントです",
      detail: "詳 細",
    },
  },
  // 在线咨询
  consultPage: {
    // 表单
    formTitle: "オンライン相談です",
    form: {
      input: "入力お願いします",
      select: "選択をお願いします",
      tips: "オペレーターがご連絡できるように、ご連絡先をお願いします。",
      title: "見出しです",
      type: "タイプです",
      content: "詳しい内容です",
      name: "名前です",
      phone: "携帯電話番号です",
      submit: "提出します",
    },
    "12315Platform": "国家12315プラットフォーム",
    "12315Tips": "‌消費者が展開するプラットフォーム",
    management: "景区管理委員会です",
    consult: "観光地相談ホットライン",
    complain: "観光区苦情ホットライン",
    rescue: "観光地区の救援ホットラインです",

    phone: {
      nav: ["カンラン山です", "二十八都の古い町", "清々とした波"],
      consult: "お問い合わせです",
      complain: "クレーム電話です",
      rescue: "救助の電話です",
    },

    search: "検索します",

    reply: "お返事します",
    categoryType: {
      consult: "お問い合わせです",
      suggest: "提案します",
      complain: "クレームです",
      other: "その他です",
    },
  },

  successMessage: "操作成功です!",
};
