export default {
  "Accept-Language": "", //请求头
  ajaxKey: "",
  // 路由
  router: {
    index: "首页",
    introduction: {
      index: "景区介绍",
      list: "景点介绍",
      detail: "景点介绍",
    },
    reserve: {
      index: "在线预订",
      list: "在线预订",
    },
    information: {
      index: "景区资讯",
      list: "景区资讯",
      detail: "资讯详情",
    },
    activity: {
      index: "活动预告",
      list: "活动预告",
      detail: "活动详情",
    },
    strategy: {
      index: "游玩攻略",
      trafficList: "景区交通",
      accommodationList: "住宿美食",
      detail: "详情",
    },
    consult: {
      index: "在线咨询",
      form: "在线咨询",
      detail: "详情",
    },
  },
  // indexPage: "首页",
  // // 顶部导航栏
  nav: {
    indexPage: "首页",
    externalLink: "江山旅游",
    //   index: "首页",
    //   introduction: {
    //     index: "景区介绍",
    //     scenicSpot: "景点介绍",
    //     virtual: "虚拟旅游",
    //     voice: "语音讲解",
    //     scenery: "景区风景",
    //     guide: "景点导览",
    //   },
    //   reserve: "在线预定",
    //   information: "景区资讯",
    //   activity: "活动预告",
    //   strategy: {
    //     index: "游玩攻略",
    //     traffic: "景区交通",
    //     accommodation: "住宿美食",
    //   },
  },
  // 左侧菜单
  rightNav: {
    ticket: "门票预订",
    consult: "在线咨询",
    visitor: "景区人流",
    tikTok: "媒体关注",
    top: "顶部",

    medium: {
      tikTokScan: "抖音扫码",
      tikTokAttention: "关注抖音号",

      wechatScan: "微信扫码",
      wechatAttention: "关注公众号",
      wechatAttentionTravel: "关注旅游服务号",
    },
  },
  // 景区流量
  scenery: {
    // Jianglang: "江郎山景区",
    // Nianbadu: "廿八都古镇",
    // Qingyang: "清漾景区",

    load: "景区承载量",
    instantaneousLoad: "瞬时承载量",
    people: "实时游客量",
  },

  // 底部信息
  footer: {
    link: "友情链接",
    linkList: [
      {
        label: "江山旅发官网",
        value: "http://www.zjjslf.com/",
      },
      // {
      //   label: "江山信息网",
      //   value: "http://www.21js.com/",
      // },
      {
        label: "江山政府网",
        value: "http://www.jiangshan.gov.cn/",
      },
      {
        label: "江山新闻网",
        value: "http://www.js-news.cn/",
      },
      {
        label: "江山旅游网",
        value: "http://ct.zj.gov.cn/",
      },
    ],
    copyright:
      "主办：江山市旅游发展有限公司  电话：0570-4015911  地址：江山市景星西路万商城10号  技术支持：衢报智慧科技有限公司",
    recordFiling: {
      mps: "浙公网安备 33088102002129号",
      icp: "浙ICP备 16031112号-1",
    },
    // "浙公网安备 33088102002129号    浙ICP备 16031112号-1",
  },
  // 分页
  page: {
    prev: "上一页",
    next: "下一页",

    pagePrefix: "第",
    pageSuffix: "页",

    goToPrefix: "到第",
    goToSuffix: "页",

    confirm: "确定",

    totalPrefix: "共",
    totalSuffix: "页",
  },
  all: "全部",

  breadcrumb: "您当前的位置",
  vr: "虚拟旅游",
  voice: "语音讲解",
  traffic: "景区交通",
  food: "美食娱乐",
  accommodation: "住宿推荐",
  consult: "在线咨询",
  reserve: "在线预订",
  buyTicket: "在线购票",

  introduction: "景区介绍",
  booking: "预约购票",
  detail: "详情",
  more: "更多",

  startingPrice: "起",

  // 首页
  index: {
    firstTitle: "",
    firstTips:
      "江郎山·廿八都旅游区由江郎山、廿八都古镇、清漾三个主要部分组成，景区旅游资源丰富，集丹霞地貌、古建民居、毛氏文化于一体，是世界自然遗产地、国家级风景名胜区和国家AAAAA级旅游景区。",
    information: "景区资讯",
    informationTips: "各个景区的新闻动态，媒体信息",
    activity: "活动预告",
    activityTips: "各个景区开展活动的预告及新闻报道",
    activityAll: "全部资讯",

    food: "住宿美食娱乐",
    foodDetail: "详 情",
  },
  // 景区介绍
  introductionPage: {
    scenicSpot: "景点",
    introduce: "介绍",
    tips: "江郎山·廿八都旅游区—具有优越的地理位置与天然秀美的环境资源，满足游客的休憩、康体、运动、休闲娱乐等需求",

    scenicArea: "景区",
    scenery: "风光",
  },
  // 在线预订
  reservePage: {
    ticket: "门票",
    reserve: "预定",
    tips: "在线预定各大景区门票，以优惠的价格快速预定并安全出票",

    monthlySales: "月销量",
    comments: "评论",
    time: "开放时间",
    address: "地址",
  },
  // 景区资讯
  information: {
    information: "景区资讯",
    ticket: "门票预定",
    reserve: "预定",
  },
  // 活动预告
  activity: {
    activity: "活动",
    notice: "预告",
    tips: "各大景区的活动预告以及活动相关的新闻报道",
  },

  // 游玩攻略
  strategy: {
    // 景区交通
    traffic: {
      scenicArea: "景区",
      traffic: "交通",
      tips: "各大景区的交通信息，大交通以及景区内的路线信息",
    },
    // 住宿美食
    food: {
      food: "住宿美食娱乐",
      detail: "详 情",
    },
  },
  // 在线咨询
  consultPage: {
    // 表单
    formTitle: "在线咨询",
    form: {
      input: "请输入",
      select: "请选择",
      tips: "请您留下联系方式，以便客服人员与您联系",
      title: "标题",
      type: "类型",
      content: "详细内容",
      name: "姓名",
      phone: "手机号",
      submit: "提交",
    },
    "12315Platform": "全国12315平台",
    "12315Tips": "‌消费者维权平台",
    management: "景区管委会",
    consult: "景区咨询热线",
    complain: "景区投诉热线",
    rescue: "景区救援热线",

    phone: {
      nav: ["江郎山", "廿八都", "清漾"],
      consult: "咨询电话",
      complain: "投诉电话",
      rescue: "救援电话",
    },

    search: "搜索",

    reply: "回复",
    categoryType: {
      consult: "咨询",
      suggest: "建议",
      complain: "投诉",
      other: "其他",
    },
  },

  successMessage: "操作成功！",
};
