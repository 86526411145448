export default {
  "Accept-Language": "KOR", //请求头
  ajaxKey: "expand3",
  // 路由
  router: {
    index: "첫 페이지",
    introduction: {
      index: "관광지 소개",
      list: "명소 소개",
      detail: "명소 소개",
    },
    reserve: {
      index: "온라인 예약",
      list: "온라인 예약",
    },
    information: {
      index: "관광지 정보",
      list: "관광지 정보",
      detail: "정보상세정보",
    },
    activity: {
      index: "행사 예고",
      list: "행사 예고",
      detail: "활동 내역",
    },
    strategy: {
      index: "플레이전략",
      trafficList: "관광지 교통",
      accommodationList: "맛있는 숙박 음식",
      detail: "자세 한 정보",
    },
    consult: {
      index: "온라인 자문",
      form: "온라인 자문",
      detail: "자세 한 정보",
    },
  },
  // indexPage: "첫 페이지",
  // 顶部导航栏
  nav: {
    indexPage: "첫 페이지",
    externalLink: "강산 여행 사이트",
    //   index: "첫 페이지",
    //   introduction: {
    //     index: "관광지 소개",
    //     scenicSpot: "명소 소개",
    //     virtual: "가상 여행",
    //     voice: "음성 강해",
    //     scenery: "관광지 풍경",
    //     guide: "관광지 안내",
    //   },
    //   reserve: "온라인 예약",
    //   information: "관광지 정보",
    //   activity: "행사 예고",
    //   strategy: {
    //     index: "플레이전략",
    //     traffic: "관광지 교통",
    //     accommodation: "맛있는 숙박 음식",
    //   },
  },
  // 左侧菜单
  rightNav: {
    ticket: "입장권 예약",
    consult: "온라인 자문",
    visitor: "관광지 인파",
    tikTok: "미디어 주목",
    top: "꼭대기",

    medium: {
      tikTokScan: "소리를 털고 코드를 스캔하다",
      tikTokAttention: "두음 (두음)을 주목하라",

      wechatScan: "위챗 스캐너",
      wechatAttention: "공중계정 관심",
      wechatAttentionTravel: "여행 서비스 번호를 주목하세요",
    },
  },
  // 景区流量
  scenery: {
    // Jianglang: "江郎山景区",
    // Nianbadu: "廿八都古镇",
    // Qingyang: "清漾景区",

    load: "관광 지구 수용량",
    instantaneousLoad: "순간 적재량",
    people: "실시간 여행객 수",
  },

  // 底部信息
  footer: {
    link: "사용자 정의 링크",
    linkList: [
      {
        label: "강산 여발 공식 사이트",
        value: "http://www.zjjslf.com/",
      },
      // {
      //   label: "강산 정보망",
      //   value: "http://www.21js.com/",
      // },
      {
        label: "강산 정부 네트워크",
        value: "http://www.jiangshan.gov.cn/",
      },
      {
        label: "장산뉴스넷",
        value: "http://www.js-news.cn/",
      },
      {
        label: "강산 여행 사이트",
        value: "http://ct.zj.gov.cn/",
      },
    ],
    copyright:
      "주최:장산시여행발전유한회사 전화:0570-4015911 주소:장산시 경성서로완쇼핑몰 10호 기술지원:구보 지혜 과학기술유한회사",
    recordFiling: {
      mps: "지궁왕안페이 33088102002129",
      icp: "지궁 icp 페이 16031112호-1",
    },
  },
  // 分页
  page: {
    prev: "이전 페이지",
    next: "다음 페이지",

    pagePrefix: "",
    pageSuffix: "페지",

    goToPrefix: "",
    goToSuffix: "페이지까지",

    confirm: "확정",

    totalPrefix: "모두",
    totalSuffix: "페이지",
  },
  all: "전부다",

  breadcrumb: "현재 위치입니다",
  vr: "가상 여행",
  voice: "음성 강해",
  traffic: "관광지 교통",
  food: "푸드 엔터테인먼트",
  accommodation: "숙박 추천",
  consult: "온라인 자문",
  reserve: "온라인 예약",
  buyTicket: "온라인 티켓 구매",

  introduction: "관광지 소개",
  booking: "티켓을 예약하다",
  detail: "자세히",
  more: "더 많은",

  startingPrice: "시작 가격",

  // 首页
  index: {
    firstTitle: "장랑산 · 이십팔도 고대 마을",
    firstTips:
      "장랑산 · 이십팔도 관광지는 장랑산, 이십팔도 구진, 청양 등 3개 주요 부분으로 구성되어 있으며 관광자원이 풍부하고 단하 (丹霞) 지형, 고건 (古建筑), 마오씨 (毛氏) 문화가 일체화되어 있으며 세계자연유산지, 국가급 풍경명승지, 국가 AAAAA 급 관광지이다.",
    information: "관광지 정보",
    informationTips: "각 관광지의 뉴스 동태, 매체 정보",
    activity: "행사 예고",
    activityTips: "각 관광 지구의 활동 예고와 뉴스 보도",
    activityAll: "전체 정보",

    food: "미식오락 숙박",
    foodDetail: "자세히",
  },
  // 景区介绍
  introductionPage: {
    scenicSpot: "관광 명소",
    introduce: "소개",
    tips: "장랑산·이십팔도 고대 마을관광 지역-우월한 지리적 위치와 아름다운 자연 환경 자원을 가지고, 휴식의 수요를 만족, 건강, 스포츠, 레크리에이션",

    scenicArea: "관광지",
    scenery: "경치",
  },
  // 在线预订
  reservePage: {
    ticket: "입장권",
    reserve: "예정",
    tips: "온라인으로 각 관광지 입장권을 예약하며, 우대 가격으로 신속하게 예약하고 안전하게 티켓을 발행하다",

    monthlySales: "월간 판매",
    comments: "논평",
    time: "개방 시간",
    address: "주소",
  },
  // 景区资讯
  information: {
    information: "관광지 정보",
    ticket: "입장권 예약",
    reserve: "예정",
  },
  // 活动预告
  activity: {
    activity: "활동",
    notice: "예고",
    tips: "각 관광 지구의 행사 예고 및 행사 관련 뉴스 보도",
  },

  // 游玩攻略
  strategy: {
    // 景区交通
    traffic: {
      scenicArea: "관광지",
      traffic: "교통",
      tips: "관광지별 교통정보, 교통정보 및 관광지내 로선정보",
    },
    // 住宿美食
    food: {
      food: "미식오락 숙박",
      detail: "자세히",
    },
  },
  // 在线咨询
  consultPage: {
    // 表单
    formTitle: "온라인 자문",
    form: {
      input: "입력하십시오",
      select: "선택하십시오",
      tips: "고객 상담원이 연락할 수 있도록 연락처를 남겨 주세요",
      title: "제목",
      type: "유형",
      content: "자세한 내용",
      name: "이름",
      phone: "핸드폰 번호",
      submit: "제출",
    },
    "12315Platform": "전국 12315 플랫폼",
    "12315Tips": "‌ 소비자 권익보호 플랫폼",
    management: "관광 지구 관리 위원회",
    consult: "관광지 문의 핫라인",
    complain: "관광지 민원 핫라인",
    rescue: "관광지 구조 핫라인",

    phone: {
      nav: ["장랑산", "이십팔도 고대 마을", "맑은 물결"],
      consult: "문의 전화",
      complain: "소송 전화",
      rescue: "구조 전화",
    },

    search: "검색",

    reply: "답장",
    categoryType: {
      consult: "자문",
      suggest: "건의",
      complain: "고소",
      other: "기타",
    },
  },

  successMessage: "작업이 성공했습니다!",
};
