export default {
  "Accept-Language": "EN", //请求头
  ajaxKey: "expand1",
  // 路由
  router: {
    index: "index",
    introduction: {
      index: "Scenic Spot Introduction",
      list: "Tourist Attraction Introduction",
      detail: "Tourist Attraction Introduction",
    },
    reserve: {
      index: "Online booking",
      list: "Online booking",
    },
    information: {
      index: "Scenic area information",
      list: "Scenic area information",
      detail: "Information details",
    },
    activity: {
      index: "Activity notice",
      list: "Activity notice",
      detail: "Activity details",
    },
    strategy: {
      index: "Play guide",
      trafficList: "Traffic in scenic area",
      accommodationList: "Accommodation food",
      detail: "details",
    },
    consult: {
      index: "Online consultation",
      form: "Online consultation",
      detail: "Consultation details",
    },
  },
  // indexPage: "Home Page",
  // 顶部导航栏
  nav: {
    indexPage: "Home Page",
    externalLink: "Jiangshan travel website",
    //   index: "home page",
    //   introduction: {
    //     index: "introduction",
    //     scenicSpot: "scenic spot",
    //     virtual: "virtual",
    //     voice: "voice",
    //     scenery: "scenery",
    //     guide: "guide",
    //   },
    //   reserve: "reserve",
    //   information: "information",
    //   activity: "activity",
    //   strategy: {
    //     index: "strategy",
    //     traffic: "traffic",
    //     accommodation: "accommodation and food",
    //   },
  },
  // 左侧菜单
  rightNav: {
    ticket: "ticket reservation",
    consult: "consult",
    visitor: "visitors flow",
    tikTok: "medium",
    top: "to top",

    medium: {
      tikTokScan: "TikTok Scan",
      tikTokAttention: "Follow TikTok account",

      wechatScan: "Wechat Scan",
      wechatAttention: "Follow the wechat public account",
      wechatAttentionTravel: "Follow the wechat travel service number",
    },
  },
  // 景区流量
  scenery: {
    // Jianglang: "Jianglang Mountain",
    // Nianbadu: "Nianbadu Ancient Town",
    // Qingyang: "Qingyang Cultural Village",

    load: "capacity",
    instantaneousLoad: "Instantaneous capacity",
    people: "real-time visitor counts",
  },

  // 底部信息
  footer: {
    link: "Friendly link",
    linkList: [
      {
        label: "Jiangshan travel official website",
        value: "http://www.zjjslf.com/",
      },
      // {
      //   label: "Jiangshan Information Network",
      //   value: "http://www.21js.com/",
      // },
      {
        label: "Jiangshan government network",
        value: "http://www.jiangshan.gov.cn/",
      },
      {
        label: "Jiangshan news network",
        value: "http://www.js-news.cn/",
      },
      {
        label: "Jiangshan travel website",
        value: "http://ct.zj.gov.cn/",
      },
    ],
    copyright:
      "Host: Jiangshan Tourism Development Co., LTD Tel: 0570-4015911 Address: No. 10, Wanshang Mall, Jingxing West Road, Jiangshan City Technical support: Qubao Smart Technology Co., LTD",
    recordFiling: {
      mps: "Zhejiang public network security No. 33088102002129",
      icp: "Zhejiang ICP prepared No. 16031112-1",
    },
  },
  // 分页
  page: {
    prev: "Previous page",
    next: "Next page",

    pagePrefix: "Page ",
    pageSuffix: "",

    goToPrefix: "Go to",
    goToSuffix: "",

    confirm: "confirm",

    totalPrefix: "Total ",
    totalSuffix: "",
  },
  all: "All",

  breadcrumb: "Your current location",
  vr: "Virtual tourism",
  voice: "Voice explanation",
  traffic: "Traffic in scenic area",
  food: "Food entertainment",
  accommodation: "Accommodation recommendation",
  consult: "Online consultation",
  reserve: "Online booking",
  buyTicket: "Buy tickets online",

  introduction: "Scenic spot introduction",
  booking: "Booking tickets",
  detail: "detail",
  more: "more",

  startingPrice: "starting price",
  // 首页
  index: {
    firstTitle: "Jianglang Mountain · Nianbadu Tourist Area",
    firstTips:
      "Jianglang Mountain · Nianbadu Tourist Area consists of three main parts: Jianglang Mountain, Nianbadu Ancient Town, and Qingyang Cultural village. The scenic area is rich in tourism resources.  It integrates the culture of Danxia landform, ancient residential buildings, and Mao’ culture. It is a world natural heritage site, national scenic spot, and national AAAAA level tourist attraction.",
    information: "Scenic area information",
    informationTips: "News and media information of each scenic spot",
    activity: "Activity notice",
    activityTips:
      "Advance notice and news report of activities carried out in each scenic spot",
    activityAll: "All activity",
    food: "Accommodation food entertainment",
    foodDetail: "detail",
  },
  // 景区介绍
  introductionPage: {
    scenicSpot: "Scenic spot",
    introduce: " introduce",
    tips: "Jianglang Mountain · 28 Tourist area - with superior geographical location and natural and beautiful environmental resources, to meet the needs of tourists for rest, health, sports, leisure and entertainment",

    scenicArea: "scenic area",
    scenery: " scenery",
  },
  // 在线预订
  reservePage: {
    ticket: "Admission ticket",
    reserve: " reserve",
    tips: "Book online tickets for major scenic spots, book quickly and safely at a discounted price",

    monthlySales: "Monthly sales",
    comments: "Comments",
    time: "Opening hours",
    address: "Address",
  },
  // 景区资讯
  information: {
    information: "Scenic area information",
    ticket: "Ticket reservation",
    reserve: "reserve",
  },
  // 活动预告
  activity: {
    activity: "Activity ",
    notice: "notice",
    tips: "Event preview of major scenic spots and news reports related to the event",
  },

  // 游玩攻略
  strategy: {
    // 景区交通
    traffic: {
      scenicArea: "Scenic area",
      traffic: " traffic",
      tips: "Traffic information of each major scenic spot, major traffic and route information within the scenic spot",
    },
    // 住宿美食
    food: {
      food: "Accommodation food entertainment",
      detail: "detail",
    },
  },
  // 在线咨询
  consultPage: {
    // 表单
    formTitle: "Online consultation",
    form: {
      input: "Please enter ",
      select: "Please select ",
      tips: "Please leave your contact information so that the customer service staff can contact you",
      title: "title",
      type: "type",
      content: "Detailed content",
      name: "name",
      phone: "phone",
      submit: "submit",
    },
    "12315Platform": "National 12315 platform",
    "12315Tips": "‌ Consumer rights platform",
    management: "Scenic area management committee",
    consult: "Scenic spot hotline",
    complain: "Scenic area complaint hotline",
    rescue: "Scenic rescue hotline",

    phone: {
      nav: [
        "Jianglang Mountain",
        "Nianbadu Ancient Town",
        "Qingyang Cultural Village",
      ],
      consult: "Consulting Line",
      complain: "Complaint Line",
      rescue: "Rescue Line",
    },

    search: "search",

    reply: "reply",
    categoryType: {
      consult: "consult",
      suggest: "suggest",
      complain: "complain",
      other: "other",
    },
  },

  successMessage: "Operation is successful!",
};
